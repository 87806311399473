
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.speakers-v3 {
  overflow: hidden;
}

.speakers-v3__wrapper {
  @include media(xl) {
    display: grid;
    grid-gap: 56px 292px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 768px 1fr;
  }
}

.speakers-v3__title {
  margin-bottom: 32px;

  @include media(md, md-max) {
    width: 396px;
  }

  @include media(lg) {
    margin-bottom: 56px;
  }

  @include media(xl) {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-bottom: 0;
  }
}

.speakers-v3__list {
  @include ulres;

  display: flex;
  align-items: stretch;

  @include media(xl) {
    grid-row: 2/-1;
    grid-column: 1/-1;
  }
}

.speakers-v3__item {
  position: relative;
  flex-shrink: 0;
  width: 280px;

  @include media(md) {
    width: 344px;
  }

  @include media(lg) {
    width: 460px;
  }

  @include media(xl) {
    width: 384px;
  }
}

.speakers-v3__list--static .speakers-v3__item {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.speakers-v3__button {
  @include inpres;

  width: 100%;
  text-align: left;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.speakers-v3__media {
  position: relative;
  display: block;
  margin-bottom: 20px;
  border-radius: 16px;
  overflow: hidden;

  @include media(lg) {
    margin-bottom: 24px;
    border-radius: 20px;
  }
}

.speakers-v3__picture {
  width: 100%;
  height: 350px;
  transition: all 250ms ease-out;

  @include media(md) {
    height: 428px;
  }

  @include media(lg) {
    height: 564px;
  }

  @include media(xl) {
    height: 476px;
  }
}

@include hover('.speakers-v3__button:not(:disabled)') {
  .speakers-v3__picture {
    transform: scale(1.05);
  }
}

.speakers-v3__item:focus-within .speakers-v3__picture {
  transform: scale(1.05);
}

.speakers-v3__company {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 4px;
  border-radius: 10px;
  background-color: rgba(var(--bg-main-color-rgb), .8);

  @include media(lg) {
    padding-right: 16px;
  }
}

.speakers-v3__company-logo {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 8px;
  overflow: hidden;

  @include media(lg) {
    width: 28px;
    height: 28px;
  }
}

.speakers-v3__info-icon {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 32px;
  height: 32px;
  color: var(--accent-always-white-color);

  @include media(lg) {
    display: none;
  }
}

.speakers-v3__name {
  display: block;
  width: 248px;
  margin-bottom: 6px;
  text-align: left;

  @include media(lg) {
    width: 320px;
    margin-bottom: 10px;
  }
}

.speakers-v3__experience {
  width: 248px;
  margin-bottom: 0;
  color: var(--text-secondary-color);

  @include media(lg) {
    width: 320px;
  }
}

// TODO: выпилить костыли, когда будем обновлять кнопки слайдера
.speakers-v3__controls {
  @include media(lg-max) {
    display: none;
  }

  @include media(xl) {
    grid-row: 1/2;
    grid-column: 2/-1;
    align-self: flex-end;
    justify-self: flex-end;
  }

  .button-arrow {
    width: 48px;
    height: 48px;

    &--prev {
      margin-right: 12px;
      transform: unset;
    }
  }
}

// Popup
.speakers-v3-popup {
  min-height: 434px;

  @include media(md) {
    width: 696px;
    min-height: 244px;
  }

  @include media(lg) {
    width: 800px;
    min-height: 260px;
  }

  &--centered {
    @include media(lg) {
      width: 928px;
    }

    @include media(xl) {
      width: 1080px;
    }
  }

  .ui-modal__scroll {
    @include media(md, md-max) {
      padding-right: 52px;
      padding-left: 52px;
    }
  }
}

.speakers-v3-popup__author {
  @include media(lg) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 168px 1fr;
  }
}

.speakers-v3-popup__picture {
  display: block;
  width: 168px;
  height: 208px;
  border-radius: 16px;
  overflow: hidden;

  @include media(md-max) {
    margin-bottom: 32px;
  }

  @include media(lg) {
    grid-row: 1/-1;
    grid-column: 1/2;
  }
}

.speakers-v3-popup__title {
  margin-bottom: 8px;

  @include media(lg) {
    grid-row: 1/2;
    grid-column: 2/-1;
    margin-bottom: 12px;
  }
}

.speakers-v3-popup__experience {
  display: block;
  margin-bottom: 20px;
  color: var(--text-secondary-color);

  @include media(lg) {
    grid-row: 2/3;
    grid-column: 2/-1;
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.speakers-v3-popup__desc {
  margin-bottom: 0;

  @include media(lg) {
    grid-row: 3/-1;
    grid-column: 2/-1;
  }
}

.speakers-v3-popup__examples {
  margin-top: 56px;

  @include media(md) {
    margin-top: 60px;
  }

  @include media(lg) {
    margin-top: 72px;
  }
}

.speakers-v3-popup__subtitle {
  margin-bottom: 24px;
}

.speakers-v3-popup__list {
  @include ulres;

  &:not(:last-child) {
    margin-bottom: 56px;
  }
}

.speakers-v3-popup__item {
  &:not(:last-child) {
    margin-bottom: 24px;

    @include media(lg) {
      margin-bottom: 40px;
    }
  }
}

.speakers-v3-popup__media-wrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  @include media(sm-max) {
    padding-bottom: calc(100% / 16 * 9);
  }

  @include media(md) {
    width: 100%;
    height: 324px;
  }

  @include media(lg) {
    height: 432px;
  }

  &--image {
    display: block;
  }

  img {
    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.speakers-v3-popup__media {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;

  @include media(sm-max) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.speakers-v3-popup__pic-desc {
  display: block;
  margin-top: 12px;
  color: var(--text-secondary-color);
}

.speakers-v3-popup__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
}

.speakers-v3-popup__details {
  @include inpres;

  display: block;
  width: 100%;
  cursor: pointer;
}
